import React from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

export default function Redirecter (props){
    const history = useHistory();
    const query = new URLSearchParams(props.location.search);

    const sensorId = query.get("sensorId");
    if ( sensorId !== null ) {
        axios.post('/GetSensor.php', {
            sensorId: sensorId
        })
        .then(response => {
            const cellId = response.data[0].cellId;
            console.log("redirect to /table/" + cellId + "/?sensorId=" + sensorId);
            history.push("/table/" + cellId + "/?sensorId=" + sensorId);
        })
        .catch(error => {
            console.log(error);
            //here we can also log the error to the server if we want to keep track of that
        });
    } else {
        history.push("/overview");
    }
    return <h1>Redirecting to correct path...</h1>
}
import React from 'react';
import Classes from './Layout.module.css';
import HeaderBar from '../../Navigation/HeaderBar/HeaderBar';

const Layout = (props) => {
        return (
            <div>
                <HeaderBar cells={props.cells}/>
                {props.children}
                <div className={Classes.Background}></div>
            </div>
        );
}

export default Layout;
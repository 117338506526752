import React from 'react';
import Classes from './PositionButton.module.css'
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import GridOnIcon from '@material-ui/icons/GridOn';

export default function PositionButton(props) {
    return (
        <Link to={{ pathname: "/layout/" + props.cellId }} className={Classes.PositionButton}>
            <Fab color="primary" size="small">
                <GridOnIcon/>
            </Fab>
        </Link>
    );
}
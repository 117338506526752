import React, { useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ViewListIcon from '@material-ui/icons/ViewList';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SideDrawer(props) {
  const urlHash = window.location.hash.split('/');
  const cellId = parseInt(urlHash.pop());
  let page = urlHash.pop();
  const [selected, setSelected] = useState(cellId);
  const [openList, setOpenList] = useState(true);

  const styleLink = {
    textDecoration: 'none',
    color: 'inherit'
  };

  page = page === '#' ? 'table' : page;

  if (selected !== cellId && !isNaN(cellId)) {
    setSelected(cellId)
  } else if (selected !== 'nothing selected' && isNaN(cellId)) {
    setSelected('nothing selected')
  }

  const handleOpenListClick = () => {
    setOpenList(!openList);
  };

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="left"
        open={props.showSideDrawer}
        onClose={props.toggleDrawer(false)}
        onOpen={props.toggleDrawer(true)}
      >

        <List>
          <ListItem>
            <ListItemText primary="Koelhuis: Koelvries Creil BV" />
          </ListItem>
          <Divider />
          <ListItemLink href="#overview" onClick={props.toggleDrawer(false)}>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary="Overzicht" />
          </ListItemLink>
          <ListItem button onClick={handleOpenListClick}>
            <ListItemIcon>
              <AcUnitIcon />
            </ListItemIcon>
            <ListItemText primary="Koelcellen" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              {props.cells !== undefined ? props.cells.map(cell => {
                return (
                  <NavLink exact key={cell.cellId} style={styleLink} to={'/' + page + '/' + cell.cellId} onClick={props.toggleDrawer(false)}>
                    <ListItem button key={cell.cellId} selected={cell.cellId === selected}>
                      <ListItemIcon>
                        <AcUnitIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Koelcel ' + cell.cellId} />
                    </ListItem>
                  </NavLink>
                )
              }) : null}
            </List>
          </Collapse>
          <Divider />
        </List>
        <List component="div" style={{ bottom: '0' }}>
          <ListItemLink href="#logout">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log uit" />
          </ListItemLink>
          <ListItem>
            <Typography component="div">
              <Box fontSize={13} color="#333">Coldstore Insights ®</Box>
              <Box fontSize={11} color="#888">Copyright © 2021 Cyacs Process Innovations</Box>
            </Typography>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

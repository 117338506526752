import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import GoBackButton from '../../components/Navigation/GoBackButton/GoBackButton';
import Main from '../../components/TreeD/Main';
import { Component } from 'react';
import axios from 'axios';
import Spinner from '../../components/UI/Spinner/GeneralSpinner';

export default class ThreeDCellContainer extends Component {
    state = {
        sensors: undefined,
        cellMap: undefined,
        latestData: undefined,
        cellId: undefined,
        loading: false
    }

    loadNewData = () => {
        this.setState({loading: true})
        axios.post('/GetDataThreeD.php', {
            cellId: this.props.match.params.cellId
        })
            .then(response => {
                const data = response.data;
                this.setState({
                    sensors: data.sensors,
                    cellMap: JSON.parse(data.cellMap),
                    latestData: data.latestData,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    componentDidMount() {
        this.loadNewData();
    }



    render() {
        if (this.state.cellId !== this.props.match.params.cellId) {
            this.loadNewData();
            this.setState({cellId: this.props.match.params.cellId})
        }
        return (
            <Aux>
                {!this.state.loading ? <Main cellId={this.props.match.params.cellId} cellData={this.state} /> : <Spinner/>}
                <GoBackButton url={"/table/" + this.props.match.params.cellId} />
            </Aux>
        );
    }
}
import * as THREE from 'three';
import MovableObject from './super/MovableObject.js';

function sensorId3d(font, sensorId, rotationX, rotationY, x, y, z) {
    const material = new THREE.MeshStandardMaterial({ color: 0x020082 });
    let fontGeometry = new THREE.TextGeometry(sensorId, {
        font: font,
        size: .6,
        height: .05,
        curveSegments: 5,
    });
    fontGeometry.center();
    let fontMesh = new THREE.Mesh(fontGeometry, material);
    fontMesh.position.x = x;
    fontMesh.position.y = y;
    fontMesh.position.z = z;
    fontMesh.rotation.x += rotationX * Math.PI / 180;
    fontMesh.rotation.y += rotationY * Math.PI / 180;
    return fontMesh;
}

function sprite(data) {
    let temp = "Temp: " + data.temperature + "°C";
    let battType = "?";
    switch (data.batteryType) {
        case "alkaline":
            battType = "A";
            break;
        case "li-ion":
            battType = "LI";
            break;
        case "SOCB":
            battType = "S";
            break;
        default: 
            battType = "?";
            break;
    }
    let batt = "Batt(" + battType + "): " + data.batteryLevel + "V";
    let RSSI = "RSSI: " + data.RSSI;
    let fontface = "Arial";
    var fontsize = 42;
    var backgroundColor = { r: 255, g: 255, b: 255, a: 1.0 };
    var textColor = { r: 0, g: 0, b: 0, a: 1.0 };

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = "Bold " + fontsize + "px " + fontface;

    context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," + backgroundColor.b + "," + backgroundColor.a + ")";
    context.fillRect(0, 0, canvas.width, canvas.height)
    let step = 45;
    context.fillStyle = "rgba(" + textColor.r + ", " + textColor.g + ", " + textColor.b + ", 1.0)";
    context.fillText(temp, 10, step * 1);
    context.fillStyle = "rgba(" + textColor.r + ", " + textColor.g + ", " + textColor.b + ", 1.0)";
    context.fillText(batt, 10, step * 2);
    context.fillStyle = "rgba(" + textColor.r + ", " + textColor.g + ", " + textColor.b + ", 1.0)";
    context.fillText(RSSI, 10, step * 3);

    var texture = new THREE.Texture(canvas)
    texture.needsUpdate = true;

    var spriteMaterial = new THREE.SpriteMaterial({ map: texture });
    var sprite = new THREE.Sprite(spriteMaterial);
    let scale = 1.58;
    sprite.scale.set(1 * scale, .6 * scale, 1 * scale);
    sprite.position.set(1.28, 1.42, 0);
    return sprite;
}

export default class Sensor extends MovableObject {
    constructor(uuid, sensorId, cellId, data) {
        let boxSize = .95
        const geometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
        const material = new THREE.MeshStandardMaterial({ color: 0xffffff, opacity: 1, transparent: true });
        let mesh = new THREE.Mesh(geometry, material);
        mesh.position.y = 0.5;
        let group = new THREE.Group();
        const loader = new THREE.FontLoader();
        let offset = .475;
        let yOffset = .5 - offset;
        loader.load('https://rawgit.com/mrdoob/three.js/dev/examples/fonts/helvetiker_regular.typeface.json', function (font) {
            group.add(sensorId3d(font, sensorId, 0, 0, 0, offset, offset));
            group.add(sensorId3d(font, sensorId, 0, -90, -offset, offset, 0));
            group.add(sensorId3d(font, sensorId, 0, -180, 0, offset, -offset));
            group.add(sensorId3d(font, sensorId, 0, -270, offset, offset, 0));
            group.add(sensorId3d(font, sensorId, -90, 0, 0, (offset * 2) + yOffset, 0));
            group.add(sensorId3d(font, sensorId, 90, 0, 0, yOffset, 0));
        });

        group.add(sprite(data));
        group.add(mesh);
        group.userData.type = "sensor"
        group.uuid = uuid;
        group.userData.id = sensorId;
        group.userData.cellId = cellId;
        super(group);
    }

    showSprite() {
        this.mesh.children.forEach(element => {
            if (element.type === "Sprite") {
                element.visible = true;
            }
        });
    }

    closeSprite() {
        this.mesh.children.forEach(element => {
            if (element.type === "Sprite") {
                element.visible = false;
            }
        });
    }
}
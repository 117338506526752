import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import SignalIndicator from './UI/SignalIndicator/SignalIndicator';
import BatteryIndicator from './UI/BatteryIndicator/BatteryIndicator';
import ChartToggleSwitch from './DataTable/ChartToggleSwitch';
import ColorPicker from './UI/ColorPicker/ColorPickers.js';
import MyTooltip from './UI/Tooltip/MyTooltip.js';
import InfoIcon from '@material-ui/icons/InfoOutlined';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const tooltipStyle = {
        fontSize: '12px',
        fontWeight: 400
    }
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => {

                    let headCellContent = <span>
                        {headCell.label}
                        {headCell.tooltip ? <MyTooltip title={<div style={tooltipStyle}>{headCell.tooltip}</div>} interactive>
                            <span>
                                <InfoIcon style={{ fontSize: '1rem', marginLeft: '3px' }} />
                            </span>
                        </MyTooltip> : null}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                    </span>

                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'center'}
                            style={{ padding: '5px' }}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sortable ? <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCellContent}
                            </TableSortLabel> :
                                headCellContent}
                        </TableCell>
                    )
                })

                }
                {props.clickable ? <TableCell /> : null}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {props.title}
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        tooltip: {
            color: 'red'
        }
    },
    paper: {
        width: '100%',
        maxWidth: 800
    },
    table: {
        minWidth: 400,
        size: 'small',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('cellId');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Paper className={classes.paper}>
            {props.title !== undefined ? <EnhancedTableToolbar title={props.title} /> : null}
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={props.headCells}
                        clickable={props.clickable}
                    />
                    <TableBody>
                        {stableSort(props.rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => props.handleClick(row)}
                                        tabIndex={-1}
                                        key={index}
                                        style={{ cursor: props.clickable ? 'pointer' : 'default' }}
                                    >
                                        {Object.keys(row).map((key, index) => {
                                            if (
                                                key === "color" ||
                                                key === "batteryType" ||
                                                key === "maxTempSensorId" ||
                                                key === "minTempSensorId" ||
                                                key === "minBattSensorId" ||
                                                key === "minSigSensorId"
                                            ) {
                                                return;
                                            }
                                            let display;
                                            let padding = '5px';
                                            let tooltipText = "";
                                            switch (key) {
                                                case "sensorId":
                                                    display = <ColorPicker
                                                        color={row.color}
                                                        changeColorOfSensorHandler={(sensorId, color) => props.changeColorOfSensorHandler(sensorId, color)}
                                                        sensorId={row[key]}>
                                                        {row[key]}
                                                    </ColorPicker>
                                                    break;
                                                case "batteryLevel":
                                                    display = <BatteryIndicator batteryLevel={row[key]} batteryType={row.batteryType} />
                                                    let sensorIdText = row["minBattSensorId"] !== undefined ? <span>{"Sensor: " + row["minBattSensorId"]} <br></br></span> : null
                                                    tooltipText = (<span>{sensorIdText}{"Type: " + row.batteryType} <br></br> {"Niveau: " + row.batteryLevel + "V"}</span>);
                                                    break;
                                                case "signal":
                                                    display = <SignalIndicator signal={row[key]} />
                                                    tooltipText = row['minSigSensorId'] !== undefined ? "Sensor: " + row["minSigSensorId"] : "";
                                                    break;
                                                case "showLine":
                                                    display = <ChartToggleSwitch
                                                        toggleChartLine={props.toggleChartLine}
                                                        sensorId={row['sensorId']}
                                                        showLine={row[key]}
                                                    />
                                                    padding = '0px';
                                                    break;
                                                case "min":
                                                    tooltipText = "Sensor: " + row["minTempSensorId"];
                                                    display = row[key];
                                                    break;
                                                case "max":
                                                    tooltipText = "Sensor: " + row["maxTempSensorId"];
                                                    display = row[key];
                                                    break;
                                                case "temperature":
                                                    display = row[key] + ' °C';
                                                    break;
                                                default:
                                                    display = row[key]
                                                    break;
                                            }
                                            const tooltipTitle = (<span style={{ fontSize: '12px', fontWeight: 400 }}>{tooltipText}</span>);
                                            return <TableCell key={index} size='small' style={{ padding: padding }} align={index > 0 ? 'right' : 'center'} >
                                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <span style={{ marginRight: '3px' }}>{display}</span>
                                                    {tooltipText !== "" ?
                                                        <MyTooltip title={tooltipTitle}>
                                                            <InfoIcon style={{ fontSize: '1rem' }} />
                                                        </MyTooltip>
                                                        : null}
                                                </div>
                                            </TableCell>
                                        })}
                                        {props.clickable ?
                                            <TableCell align="right">
                                                <IconButton size="small">
                                                    <ArrowForwardIosIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell> : null}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

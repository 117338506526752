import React from 'react';

export default function SignalTooltip(prop) {
    return (
        <table>
            <caption><a href="https://nl.wikipedia.org/wiki/RSSI" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>RSSI</a></caption>
            <thead>
                <tr>
                    <td>Signaalsterkte</td>
                    <td>Waardering</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>-30 dBm</td>
                    <td>Verbazingwekkend</td>
                </tr>
                <tr>
                    <td>-67 dBm</td>
                    <td>Zeer goed</td>
                </tr>
                <tr>
                    <td>-70 dBm</td>
                    <td>Oké</td>
                </tr>
                <tr>
                    <td>-80 dBm</td>
                    <td>Niet goed</td>
                </tr>
                <tr>
                    <td>-90 dBm</td>
                    <td>Onbruikbaar</td>
                </tr>
            </tbody>

        </table>
    )
}
import * as THREE from 'three';
import concrete from '../textures/concrete-map.jpg'
import door from '../textures/door.jpg'
import MovableObject from './super/MovableObject.js';

const buildWalls = (length, width, height, margin) => {
    const wallLength = width + margin;
    const wallWidth = length + margin;
    const wallHeight = height + (margin / 4);
    const wallsGeometry = new THREE.BoxGeometry(wallLength, wallHeight, wallWidth);
    const wallsMaterial = new THREE.MeshPhongMaterial({
        color: 0x666666,
        emissive: 0x0,
        specular: 0x333333,
        shininess: 10,
        side: THREE.BackSide,
        map: new THREE.TextureLoader().load(concrete),
        fog: false
    });
    let wallsMesh = new THREE.Mesh(wallsGeometry, wallsMaterial);

    wallsMesh.receiveShadow = true;
    wallsMesh.position.x = width / 2;
    wallsMesh.position.y = wallHeight / 2;
    wallsMesh.position.z = length / 2;
    wallsMesh.name = "walls"

    return wallsMesh;
}

const buildDoor = (cellWidth, margin) => {
    let doorWidth = 2.65;
    let doorheight = 2.8;
    const geometry = new THREE.PlaneGeometry(doorWidth, doorheight, 32);
    const material = new THREE.MeshStandardMaterial({
        color: 0x999999,
        side: THREE.FrontSide,
        emissive: 0x0,
        map: new THREE.TextureLoader().load(door),
        fog: false
    });
    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(cellWidth / 2, doorheight / 2, -(margin / 2) + 0.01);
    return mesh;
}

export default class WareHouse extends MovableObject{
    constructor(length, width, height) {
        let group = new THREE.Group();
        const margin = 2;
        group.add(buildWalls(length, width, height, margin));
        group.add(buildDoor(width, margin));
        group.userData.type = "warehouse"
        group.uuid = "warehouse";
        super(group);
    }
}
import React from 'react';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function calculatePercentage(min, max, value) {
    max -= min;
    value -= min;
    return Math.round((value / max) * 100)
}

export default function BatteryIndicator(props) {
    const batteryLevel = parseFloat(props.batteryLevel);

    //li-ion
    let min = 3.05;
    let max = 4;
    let returnIcon = <BatteryUnknownIcon />
    const batteryType = props.batteryType;
    let style = {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'none',
        padding: 0,
        margin: 0,
        minWidth: '10px'
    }

    if (batteryLevel !== undefined && batteryLevel !== null) {

        if (batteryType === "alkaline") {
            min = 1.7;
            max = 4.5;
        }

        if (batteryLevel >= max) {
            returnIcon = <BatteryFullIcon />;
        } else if (batteryLevel <= 0) {
            returnIcon = <BatteryAlertIcon />;
        } else {
            const icons = [
                <BatteryAlertIcon />,
                <Battery20Icon />,
                <Battery30Icon />,
                <Battery50Icon />,
                <Battery60Icon />,
                <Battery80Icon />,
                <Battery90Icon />,
                <BatteryFullIcon />
            ]

            let index = 0;
            for (let volt = min; volt <= max; volt += ((max - min) / (icons.length - 1))) {
                if (batteryLevel < volt) {
                    break;
                }
                index++;
            }
            if (index === 0) {
                style = { ...style, color: "red" };
            }
            returnIcon = icons[index];
        }

    }

    const useStyles = makeStyles({
        endIconStyle: {
            marginLeft: 0,
            marginRight: '-4px'
        },
    });

    const classes = useStyles();

    return (
        <Button
            endIcon={returnIcon}
            disabled
            style={style}
            classes={{ endIcon: classes.endIconStyle }}
        >
            {calculatePercentage(min, max, batteryLevel)}%
        </Button>
    );
}
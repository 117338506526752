import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import axios from 'axios';

export default class World {
    constructor() {
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.camera = undefined;
        this.camControlsActive = true;
        this.cameraControls = undefined;
        this.light = new THREE.AmbientLight(0xffffff, 0.5);
        this.directionalLight1 = new THREE.DirectionalLight(0xffffff, 1)
        this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 1)
        this.worldObjects = {};
        this.dragControls = [];
        this.dragableUuids = [];
        this.sensors = [];
        this.requestId = undefined;
    }

    init(length, width, cameraType) {
        this.length = length;
        this.width = width;
        this.setCameraType(cameraType);

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.headerBar = document.getElementById('headerBar');
        this.renderer.setSize(window.innerWidth, window.innerHeight - this.headerBar.clientHeight - 5);
        document.getElementById('canvas').appendChild(this.renderer.domElement); //CHECK
        window.addEventListener('resize', () => this.onWindowResize(), false);

        this.renderer.setClearColor(0xffffff);
        this.directionalLight1.position.set(1, 1, -1);
        this.directionalLight2.position.set(-1, 1, 1);
        this.scene.add(this.directionalLight1)
        this.scene.add(this.directionalLight2)
        this.scene.add(this.light);

        this.animate();
    }

    onWindowResize() {
        this.camera.aspect = window.innerWidth / (window.innerHeight - this.headerBar.clientHeight - 5);
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight - this.headerBar.clientHeight - 5);
    }

    animate = () => {
        this.requestId = requestAnimationFrame(this.animate);
        this.render()
    }

    render = () => {
        this.cameraControls.update();
        this.renderer.render(this.scene, this.camera);
    }

    addObject = (object) => {
        this.worldObjects[object.mesh.uuid] = object;
        this.scene.add(object.getMesh());
    }

    addObjects = (objects) => {
        objects.forEach(object => {
            this.addObject(object);
        });
    }

    addSensor = (object, height) => {
        this.addObject(object);
        this.height = height;
        this.dragableUuids.push(object.mesh.uuid);
        this.sensors.push(object);
    }

    setDragable = (object) => {
        let dragControl = new DragControls([object.getMesh()], this.camera, this.renderer.domElement);
        dragControl.transformGroup = true
        dragControl.addEventListener('hoveron', () => { this.cameraControls.enabled = false; })
        dragControl.addEventListener('dragstart', () => { this.cameraControls.enabled = false; })
        dragControl.addEventListener('drag', function (e) {
            let x = Math.round(e.object.position.x);
            let y = Math.round(e.object.position.y);
            let z = Math.round(e.object.position.z);
            e.object.position.x = x < 0 ? 0 : x >= this.width ? this.width - 1 : x;
            e.object.position.y = y < 0 ? 0 : y >= this.height ? this.height - 1 : y;
            e.object.position.z = z < 0 ? 0 : z >= this.length ? this.length - 1 : z;
        });
        dragControl.addEventListener('dragend', (e) => {
            axios.post('/SetPositionSensor.php', {
                cellId: e.object.userData.cellId,
                sensorId: e.object.userData.id,
                x: e.object.position.x,
                y: e.object.position.y,
                z: e.object.position.z
            })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
            this.cameraControls.enabled = true
        })
        dragControl.addEventListener('hoveroff', () => { this.cameraControls.enabled = true; })
        this.dragControls.push(dragControl);
    }

    updateDragables = () => {
        this.dragControls.forEach(dragControl => {
            dragControl.dispose();
            dragControl.deactivate();
        })
        this.dragControls = [];
        this.dragableUuids.forEach(uuid => {
            this.setDragable(this.worldObjects[uuid]);
        })
    }

    toggleDrag = (lock) => {
        this.dragControls.forEach(dragControl => {
            dragControl.enabled = !lock;
        })
    }

    toggleShowDetail = (show) => {
        this.sensors.forEach(sensor => {
            if(show) {
                sensor.showSprite();
            } else {
                sensor.closeSprite();
            }

        })
    }

    setCameraType = (cameraType) => {
        let pos = this.camera === undefined ? { x: 12, y: 15, z: 17 } : this.camera.position;
        let lookat = new THREE.Vector3((this.width / 2) - .5, 3, (this.length / 2) - .5);
        if (cameraType === "PerspectiveCamera") {
            this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1000);
            this.camera.position.set(pos.x, pos.y, pos.z);
            this.camera.lookAt(lookat);
            this.cameraControls = new OrbitControls(this.camera, this.renderer.domElement);
            this.cameraControls.dampingFactor = .1;
            this.cameraControls.enableDamping = true;
            this.cameraControls.maxDistance = 100;
            this.cameraControls.minDistance = 1;
        } else {
            this.camera = new THREE.OrthographicCamera(window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000);
            this.camera.zoom = 20;
            this.camera.position.set(pos.x, pos.y, pos.z);
            this.cameraControls = new OrbitControls(this.camera, this.renderer.domElement);
            this.cameraControls.maxPolarAngle = 90 * Math.PI / 180;
            this.cameraControls.maxZoom = 250;
            this.cameraControls.minZoom = 5;
            this.camera.updateProjectionMatrix();
        }
        this.cameraControls.target = lookat
        this.cameraControls.enablePan = true;
        this.updateDragables();
    }

    endWorld = () => {
        window.cancelAnimationFrame(this.requestId)
    }
}
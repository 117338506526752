import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: '0',
    width: '100%',
    height: '90%',
    display: 'flex',
    zIndex: 1000,
    '& > * + *': {
      
    },
  },
}));

export default function GeneralSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <CircularProgress/>
        <p>Laden...</p>
    </div>
  );
}
import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function ChartToggleSwitch(props) {
    return <Switch
        size="medium"
        checked={props.showLine}
        onChange={() => props.toggleChartLine(props.sensorId, props.showLine)}
        name="showInChart"
        color="primary"
        inputProps={{ 'aria-label': 'show in chart switch' }}
    />

}
import React from 'react';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function Icon(signal) {
    const RSSI = parseFloat(signal);
    if (RSSI < -90) {
        return <SignalCellularConnectedNoInternet0BarIcon />
    } else if (RSSI < -80) {
        return <SignalCellular0BarIcon />
    } else if (RSSI < -70) {
        return <SignalCellular1BarIcon />
    } else if (RSSI < -67) {
        return <SignalCellular2BarIcon />
    } else if (RSSI < -50) {
        return <SignalCellular3BarIcon />
    } else if (RSSI => -50 && RSSI < 0) {
        return <SignalCellular4BarIcon />
    } else {
        return <SignalCellularConnectedNoInternet0BarIcon />
    }
}

export default function SignalIndicator(props) {
    const RSSI = parseFloat(props.signal);
    let style = {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'none',
        padding: 0,
        margin: 0
    }

    const useStyles = makeStyles({
        endIconStyle: {
            marginLeft: '2px',
            marginRight: '0px'
        },
    });

    const classes = useStyles();

    return (
        <Button
            endIcon={Icon(RSSI)}
            disabled
            style={style}
            classes={{ endIcon: classes.endIconStyle }}
        >
            {RSSI} dBm
        </Button>
    )
}
import classes from './ThreeDMenu.module.css';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Aux from '../../hoc/Auxiliary/Auxiliary.js';

const ITEM_HEIGHT = 48;

export default function ThreeDMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const matches = useMediaQuery('(min-width:600px)');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggeleLock = () => {
        props.toggleLock();
        handleClose();
    };

    const toggleCameraType = () => {
        props.toggleCameraType();
        handleClose();
    };

    const toggleShowDetail = () => {
        props.toggleShowDetail();
        handleClose();
    };

    return (
        <div className={matches ? classes.ThreeDMenuL : classes.ThreeDMenuM}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={toggeleLock}>
                    {props.lock ? <Aux><LockOpenIcon/>Unlock</Aux> : <Aux><LockIcon/>Lock</Aux>}
                </MenuItem>
                <MenuItem onClick={toggleCameraType}>
                    {props.cameraType === "OrthographicCamera" ? <Aux><ViewCarouselIcon/>Perspectief</Aux> : <Aux><ViewColumnIcon/>Orthografisch</Aux>}
                </MenuItem>
                <MenuItem onClick={toggleShowDetail}>
                    {props.showDetail ? <Aux><VisibilityOffIcon/>Verberg details</Aux> : <Aux><VisibilityIcon/>Laat details zien</Aux>}
                </MenuItem>
            </Menu>
        </div>
    );
}

import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

export default class Chart extends Component {
    render() {
        const chartType = this.props.chartDataType;
        return (
            <div className="chart" style={{ width: "100%", overflowX: "auto", height: "550px" }}>
                <Line
                    data={this.props.chartData}
                    options={{
                        normalize: true,
                        parsing: false,
                        animation: false,
                        spanGaps: true,
                        elements: {
                            point: {
                                radius: 0

                            },

                        },
                        // decimation: {
                        //     enabled: true,
                        //     algorithm: 'lttb',
                        //     samples: 50
                        // },
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            mode: 'label',
                            position: 'nearest',
                            intersect: false,
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                        label += tooltipItem.yLabel;
                                        label += chartType === "temperature" ? '°C' : chartType === "batteryLevel" ? ' V' : ' dBm';
                                    }
                                    return label;
                                }
                            }
                        },
                        layout: {
                            padding: {
                                bottom: 10,
                                right: 30
                            }
                        },
                        scales: {
                            // x: {
                            //     type: 'liniear'
                            // },
                            yAxes: [{
                                ticks: {
                                    callback: function (value, index, values) {
                                        return value + (chartType === "temperature" ? '°C' : chartType === "batteryLevel" ? ' V' : ' dBm');
                                    },
                                    maxRotation: 0,
                                    autoSkip: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: chartType === "temperature" ? 'graden Celsius' : chartType === "batteryLevel" ? 'Volt' : 'RSSI',
                                    fontSize: 14
                                },
                            }],
                        },
                    }}
                />
            </div>
        )
    }
}
import Chart from './Chart.js';
import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import Aux from '../../hoc/Auxiliary/Auxiliary';

export default function ChartContainer(props) {
    const [chartLabels, setChartLabels] = React.useState([]);
    const [chartDatasets, setChartDatasets] = React.useState([]);
    const [selectedChartData, setSelectedChartData] = React.useState("temperature");
    const [days, setDays] = React.useState(props.days);

    const labels = props.chartData.labels;
    React.useEffect(() => {
        setChartLabels(labels);
    }, [labels]);

    const data = props.chartData.datasets[selectedChartData]
    React.useEffect(() => {
        setChartDatasets(data);
    }, [data]);

    const chartData = {
        labels: chartLabels,
        datasets: chartDatasets
    };

    const changeSelectedChartDataHandler = (chartDataType) => {
        if (selectedChartData !== chartDataType) {
            setSelectedChartData(chartDataType)
            setChartDatasets(chartDatasets[chartDataType])
        }
    }

    const changeDaysHandler = (newDays) => {
        setDays(newDays)
        if (days !== newDays) {
            props.setDays(newDays);
        }
    }

    return (
        <Aux>
            <ButtonGroup size="small" color="primary" style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px', wordBreak: 'break-word' }}>
                <Button variant={selectedChartData === "temperature" ? "contained" : null} onClick={() => changeSelectedChartDataHandler("temperature")}>Tempera&shy;tuur</Button>
                <Button variant={selectedChartData === "batteryLevel" ? "contained" : null} onClick={() => changeSelectedChartDataHandler("batteryLevel")}>Batterij&shy;niveau</Button>
                <Button variant={selectedChartData === "RSSI" ? "contained" : null} onClick={() => changeSelectedChartDataHandler("RSSI")}>Signaal&shy;sterkte</Button>
            </ButtonGroup>
            <div style={{ marginTop: '5px', marginBottom: '5px', wordBreak: 'break-word'}}>
                <Button variant="text" disabled style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', fontWeight: 400, paddingRight: '3px'}}>Afgelopen</Button>
                <ButtonGroup size="small" color="primary" style={{marginLeft: '5px', marginRight: '5px'}}>
                    <Button variant={days === 7 ? "contained" : null} onClick={() => changeDaysHandler(7)}>7 dagen</Button>
                    <Button variant={days === 28 ? "contained" : null} onClick={() => changeDaysHandler(28)}>28 dagen</Button>
                    <Button variant={days === 365 ? "contained" : null} onClick={() => changeDaysHandler(365)}>Jaar</Button>
                </ButtonGroup>
            </div>
            <Chart chartData={chartData} chartDataType={selectedChartData} />
        </Aux>
    )
}
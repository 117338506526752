import React from 'react';
import Classes from './GoBackButton.module.css'
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function GoBackButton(props) {
    return (
        <Link to={{ pathname: props.url }} className={Classes.GoBackButton}>
            <Fab color="primary" size="small">
                <ArrowBackIcon/>
            </Fab>
        </Link>
    );
}
import React from 'react'
import { ChromePicker } from 'react-color';
import Button from '@material-ui/core/Button';
import axios from 'axios';

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
        console.log("done")
        axios.post('/SetSensorColor.php', {
            sensorId: this.props.sensorId,
            color: this.props.color
        })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    };

    handleChange = (color, event) => {
        this.props.changeColorOfSensorHandler(this.props.sensorId, color.hex)
    }

    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        return (
            <div>
                <Button variant="contained" size="small" onClick={this.handleClick} style={{background: this.props.color,
              color: 'white',
              height: 30,
              minWidth: 64}}>{this.props.children}</Button>
                { this.state.displayColorPicker ? <div style={popover}>
                    <div style={cover} onClick={this.handleClose} />
                    <ChromePicker
                        color={this.props.color}
                        onChange={this.handleChange}
                        onChangeComplete={this.handleChangeComplete}/>
                </div> : null}
            </div>
        )
    }
}

export default ColorPicker
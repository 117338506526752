import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SideDrawer from '../SideDrawer/SideDrawer';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    cursor: 'pointer'
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textTransform: 'uppercase',
    marginLeft: 12,
    lineHeight: 1
  },
  logo: {
    width: 100,
    paddingTop: 10,
    paddingBottom: 10
  }
}));

export default function HeaderBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    showSideDrawer: false
  });

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, showSideDrawer: open });
  };

  return (
    <Aux>
    <div className={classes.root} onClick={toggleDrawer(true)} id="headerBar">
      <AppBar position="static" color="primary">
        <Toolbar>
          <img src="/assets/logoNoBG2.png" alt="Cyacs Logo" className={classes.logo}/>
          <Typography variant="h6" className={classes.title}>
            <b>Coldstore</b> <span style={{fontWeight: 300}}>Insights</span>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>

    <SideDrawer toggleDrawer={toggleDrawer} showSideDrawer={state.showSideDrawer} cells={props.cells}/>
    </Aux>
  );
}
import * as THREE from 'three';
import MovableObject from './super/MovableObject.js';

export default class Crate extends MovableObject{
    constructor(x, y, z) {
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const edges = new THREE.EdgesGeometry( geometry );
        const material = new THREE.MeshBasicMaterial( {
            color: 0x2B49D0, 
            opacity: .2,
            transparent: true
        } );

        let mesh = new THREE.LineSegments( edges, material );
        mesh.position.y = 0.5;

        let group = new THREE.Group();
        group.add(mesh);
        group.userData.type = "crate"
        group.uuid = x + "" + y + "" + z ;
        super(group);
        this.moveTo(x, y, z);
    }
}
import React, { Component } from 'react';
import Canvas from './Canvas/Canvas.js';
import World from './world.js';
import WareHouse from './objects/WareHouse.js';
import Sensor from './objects/Sensor.js';
import Crate from './objects/Crate.js';
import Aux from '../../hoc/Auxiliary/Auxiliary.js';
import ThreeDMenu from '../../components/ThreeDMenu/ThreeDMenu.js';

export default class Main extends Component {
    state = {
        sensors: undefined,
        cellMap: undefined,
        latestData: undefined,
        lock: true,
        cameraType: "OrthographicCamera",
        showDetail: true,
        worldCreated: false,
        world: new World()
    }

    componentDidMount() {
        this.setState({
            sensors: this.props.cellData.sensors,
            cellMap: this.props.cellData.cellMap,
            latestData: this.props.cellData.latestData
        })
    }

    componentDidUpdate() {
        if (!this.state.worldCreated) {

            let widthZ = this.state.cellMap.length;
            let lengthX = this.state.cellMap[0].length;
            this.state.world.init(lengthX, widthZ, this.state.cameraType);

            let heightOfCell = 0;
            this.state.cellMap.forEach((Xrow, x) => {
                Xrow.forEach((heightY, z) => {
                    for (let y = 0; y < heightY; y++) {
                        let crate = new Crate(x, y, z);
                        this.state.world.addObject(crate);
                        heightOfCell = heightOfCell < heightY ? heightY : heightOfCell;
                    }
                })
            })

            const warehouse = new WareHouse(lengthX - 1, widthZ - 1, heightOfCell);
            this.state.world.addObject(warehouse);

            this.state.sensors.forEach(sensor => {
                this.state.latestData.forEach(sensorData => {
                    if (sensor.sensorId === sensorData.sensorId) {
                        let sensorObject = new Sensor('sensor' + sensor.sensorId + sensor.x + sensor.y + sensor.z, sensor.sensorId, sensor.cellId, sensorData);
                        sensorObject.moveTo(sensor.x, sensor.y, sensor.z);
                        this.state.world.addSensor(sensorObject, heightOfCell);
                    }
                })
            })
            this.state.world.updateDragables();
            this.setState({ worldCreated: true })
        }

        if (this.state.world.camera.type !== this.state.cameraType) {
            this.state.world.setCameraType(this.state.cameraType);
        }
        this.state.world.toggleDrag(this.state.lock);
        this.state.world.toggleShowDetail(this.state.showDetail);
    }

    componentWillUnmount() {
        this.state.world.endWorld()
    }

    render() {
        return (
            <Aux>
                <Canvas />
                <ThreeDMenu lock={this.state.lock} toggleLock={() => this.setState({ lock: this.state.lock ? false : true })}
                    cameraType={this.state.cameraType} toggleCameraType={() => this.setState({ cameraType: this.state.cameraType === "PerspectiveCamera" ? "OrthographicCamera" : "PerspectiveCamera" })}
                    showDetail={this.state.showDetail} toggleShowDetail={() => this.setState({ showDetail: this.state.showDetail ? false : true })} />
            </Aux>
        );
    }
}
import React from 'react';
import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Layout from '../../components/UI/Layout/Layout';
import Redirecter from '../Redirecter/Redirecter';
import CompanyOverview from '../CompanyOverview/CompanyOverview';
import CellOverview from '../CellOverview/CellOverview';
import ThreeDCellContainer from '../ThreeDCellContainer/ThreeDCellContainer';
import { Component } from 'react';
import axios from 'axios';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import { createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';

class App extends Component {
  state = {
    cells: undefined,
    loading: false,
    error: false,
    errorResponse: {}
  }

  componentDidMount() {
    this.setState({ loading: true })
    axios.post('/GetCells.php')
      .then(response => {
        const cells = response.data.map(cell => {
          return {
            cellId: parseInt(cell.cellId),
            latestData: cell.latestData.map(sensor => {
              return {
                RSSI: parseInt(sensor.RSSI),
                batteryLevel: parseFloat(sensor.batteryLevel),
                batteryType: sensor.batteryType,
                color: sensor.color,
                recordTime: sensor.recordTime,
                sensorId: parseInt(sensor.sensorId),
                temperature: parseFloat(sensor.temperature)
              }
            }),
            map: cell.map
          }
        })
        this.setState({
          error: false,
          cells: cells,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          error: true,
          errorResponse: error.request,
          loading: false
        })
        console.log(error);
        console.log(error.request)
      });
  }





  render() {
    //'#2B49D0'
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#2B49D0'
        },
      },
    });

    return (
      <HashRouter>
        <ThemeProvider theme={theme}>
          <Layout cells={this.state.cells}>
            <Switch>
              <Route path="/" exact component={Redirecter} />
              {this.state.cells !== undefined ?
                <Aux>
                  <Route path="/overview" exact render={() => <CompanyOverview cells={this.state.cells} />} />
                  <Route path='/table/:cellId' exact render={(props) => <CellOverview {...props} cells={this.state.cells} />} />
                  <Route path='/layout/:cellId' exact render={(props) => <ThreeDCellContainer {...props} />} />
                </Aux>
                : null}
            </Switch>
          </Layout>
        </ThemeProvider>
      </HashRouter>
    );
  }
}

export default App;
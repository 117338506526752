import React, { Component } from 'react';
import classes from './CellOverview.module.css';
import PositionButton from '../../components/Navigation/PositionButton/PositionButton';
import GoBackButton from '../../components/Navigation/GoBackButton/GoBackButton';
import axios from 'axios';
import SortableTable from '../../components/SortableTable';
import ChartContainer from '../../components/chartjs/ChartContainer.js';
import SignalTooltip from '../../components/UI/Tooltip/SignalTooltip';
import Aux from '../../hoc/Auxiliary/Auxiliary.js';

export default class CellOverview extends Component {
    state = {
        chartData: {
            labels: [],
            datasets: []
        },
        cellId: this.props.match.params.cellId,
        rows: undefined,
        days: 7
    }

    loadChartData = (days) => {
        let dayNames = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
        let datasets = {
            temperature: [],
            batteryLevel: [],
            RSSI: []
        };
        let xLabels = [];
        const lineStyle = {
            fill: false,
            borderWidth: 2.5
        }
        //if there is no match set the datapoint to null
        axios.post('/GetChartData.php', {
            cellId: this.props.match.params.cellId,
            days: days
        })
            .then(response => {
                this.setState({
                    chartData:  {
                        labels: [],
                        datasets: []
                    }
                })
                response.data.forEach(record => {
                    let dt = new Date(record.compactRecordTime);
                    const split = record.compactRecordTime.split("-")
                    const monthLabel = split[1] + "-" + split[2].split(":")[0] + "h";
                    const weekLabel = monthLabel + " " + dayNames[dt.getDay()];
                    let date = days > 28 ? record.compactRecordTime : days <= 7 ? weekLabel : monthLabel;
                    let xLabelAlreadyExist = false;
                    xLabels.forEach(xLabel => {
                        if (xLabel === date) {
                            xLabelAlreadyExist = true;
                        }
                    })

                    if (!xLabelAlreadyExist) {
                        xLabels.push(date);
                    }
                })

                xLabels.sort()

                response.data.forEach(record => {
                    let dt = new Date(record.compactRecordTime);
                    const split = record.compactRecordTime.split("-")
                    const monthLabel = split[1] + "-" + split[2].split(":")[0] + "h";
                    const weekLabel = monthLabel + " " + dayNames[dt.getDay()];
                    let index = xLabels.indexOf(days > 28 ? record.compactRecordTime : days <= 7 ? weekLabel : monthLabel);
                    let labelFoundTemperature = false;
                    let labelFoundBatteryLevel = false;
                    let labelFoundRSSI = false;

                    datasets.temperature.forEach(dataset => {
                        if (dataset.label === record.sensorId) {
                            dataset.data[index] = record.temperature;
                            labelFoundTemperature = true;
                        }
                    })

                    datasets.batteryLevel.forEach(dataset => {
                        if (dataset.label === record.sensorId) {
                            dataset.data[index] = record.batteryLevel;
                            labelFoundBatteryLevel = true;
                        }
                    })

                    datasets.RSSI.forEach(dataset => {
                        if (dataset.label === record.sensorId) {
                            dataset.data[index] = record.RSSI;
                            labelFoundRSSI = true;
                        }
                    })

                    if (!labelFoundTemperature) {
                        datasets.temperature.push({
                            label: record.sensorId,
                            data: [record.temperature],
                            backgroundColor: record.color,
                            borderColor: record.color,
                            ...lineStyle
                        })
                    }

                    if (!labelFoundBatteryLevel) {
                        datasets.batteryLevel.push({
                            label: record.sensorId,
                            data: [record.batteryLevel],
                            backgroundColor: record.color,
                            borderColor: record.color,
                            ...lineStyle
                        })
                    }

                    if (!labelFoundRSSI) {
                        datasets.RSSI.push({
                            label: record.sensorId,
                            data: [record.RSSI],
                            backgroundColor: record.color,
                            borderColor: record.color,
                            ...lineStyle
                        })
                    }
                })
                
                this.setState({
                    chartData: {
                        labels: xLabels,
                        datasets: datasets
                    },
                    days: days
                })

            })
            .catch(error => {
                console.log(error);
            });
    }

    setTableData() {
        let latestData;
        this.props.cells.forEach(cell => {
            if (cell.cellId === parseInt(this.props.match.params.cellId)) {
                latestData = cell.latestData;
            }
        })

        const rows = latestData.map(sensor => {
            return { 
                showLine: true, 
                sensorId: sensor.sensorId, 
                temperature: sensor.temperature, 
                batteryLevel: sensor.batteryLevel, 
                signal: sensor.RSSI, 
                recordTime: sensor.recordTime, 
                color: sensor.color, 
                batteryType: sensor.batteryType }
        })
        this.setState({ rows: rows })
    }

    componentDidMount() {
        this.loadChartData(this.state.days);
        this.setTableData();
    }

    toggleChartLine = (sensorId, hidden) => {
        const rows = this.state.rows.map(row => {
            return { ...row, showLine: row.sensorId === sensorId ? !row.showLine : row.showLine }
        });
        this.setState({ rows: rows });

        let chartData = this.state.chartData;
        Object.keys(chartData.datasets).map(key => {
            chartData.datasets[key].forEach(dataset => {
                if (parseInt(dataset.label) === sensorId) {
                    dataset.hidden = hidden;
                }
            });
        })

        this.setState({ chartData: chartData });
    }

    changeColorOfSensorHandler = (sensorId, color) => {
        const rows = this.state.rows.map(row => {
            return { ...row, color: row.sensorId === sensorId ? color : row.color }
        });
        this.setState({ rows: rows });

        let chartData = this.state.chartData;
        Object.keys(chartData.datasets).map(key => {
            chartData.datasets[key].forEach(dataset => {
                if (parseInt(dataset.label) === sensorId) {
                    dataset.backgroundColor = color;
                    dataset.borderColor = color;
                }
            })
        })

        this.setState({
            chartData: chartData
        })
    }

    render() {
        if (this.state.cellId !== this.props.match.params.cellId) {
            this.setState({ cellId: this.props.match.params.cellId })
            this.loadChartData(this.state.days);
            this.setTableData();
        }
        const query = new URLSearchParams(this.props.location.search);
        let highlightedSensor = query.get("sensorId")

        const headCells = [
            { id: 'chartToggle', numeric: false, disablePadding: true, sortable: false, label: 'Grafiek' },
            { id: 'sensorId', numeric: true, disablePadding: false, sortable: true, label: 'Sensor' },
            { id: 'temperature', numeric: true, disablePadding: true, sortable: true, label: <Aux>Tempe&shy;ratuur</Aux> },
            { id: 'batteryLevel', numeric: true, disablePadding: true, sortable: true, label: <Aux>Batterij&shy;niveau</Aux> },
            { id: 'signal', numeric: true, disablePadding: true, sortable: true, label: <Aux>Signaal&shy;sterkte</Aux>, tooltip: <SignalTooltip/> },
            { id: 'recordTime', numeric: false, disablePadding: false, sortable: true, label: 'Laatste meting' },
        ];

        const handleClick = (row) => {
        }
        return (
            <div className={classes.CellOverview}>
                <ChartContainer
                    chartData={this.state.chartData}
                    days={this.state.days}
                    setDays={(days) => {
                        this.loadChartData(days);
                    }} />
                {this.state.rows !== undefined ?
                    <SortableTable
                        rows={this.state.rows}
                        headCells={headCells}
                        handleClick={handleClick}
                        toggleChartLine={this.toggleChartLine}
                        changeColorOfSensorHandler={this.changeColorOfSensorHandler} /> : null}
                {highlightedSensor !== null ? <p>Uitgelichte sensor nr. {highlightedSensor}</p> : null}
                <PositionButton cellId={this.props.match.params.cellId} />
                <GoBackButton url="/overview" />
            </div>
        )
    }
}


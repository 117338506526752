import React from 'react';
import classes from './CompanyOverview.module.css'
import SortableTable from '../../components/SortableTable';
import { withRouter } from "react-router-dom";
import SignalTooltip from '../../components/UI/Tooltip/SignalTooltip';
import Aux from '../../hoc/Auxiliary/Auxiliary.js';

function calculateValues(latestData) {
  const firstOccurrence = latestData[0];
  let count = 0;
  let sum = 0;
  let min = firstOccurrence.temperature;
  let max = firstOccurrence.temperature;
  let minBatt = firstOccurrence.batteryLevel;
  let batteryType = firstOccurrence.batteryType;
  let minRSSI = firstOccurrence.RSSI;
  let minBattSensorId = firstOccurrence.sensorId;
  let minSigSensorId = firstOccurrence.sensorId;
  let minTempSensorId = firstOccurrence.sensorId;
  let maxTempSensorId = firstOccurrence.sensorId;

  latestData.forEach(sensor => {
    Object.keys(sensor).map(key => {
      const value = sensor[key]
      switch (key) {
        case "temperature":
          if (value < min) {
            min = value;
            minTempSensorId = sensor.sensorId;
          }

          if (value > max) {
            max = value;
            maxTempSensorId = sensor.sensorId;
          }

          sum += value;
          count++;
          break;
        case "batteryLevel":
          if (value < minBatt) {
            minBatt = value;
            batteryType = sensor.batteryType;
            minBattSensorId = sensor.sensorId;
          }
          break;
        case "RSSI":
          if (value < minRSSI) {
            minRSSI = value;
            minSigSensorId = sensor.sensorId;
          }
          break;
        default:
          break;
      }
    })
  });
  const average = Math.round(((sum / count) + Number.EPSILON) * 100) / 100
  return {
    average: average,
    min: min,
    max: max,
    minBatt: minBatt,
    minRSSI: minRSSI,
    batteryType: batteryType,
    minBattSensorId: minBattSensorId,
    minSigSensorId: minSigSensorId,
    minTempSensorId: minTempSensorId,
    maxTempSensorId: maxTempSensorId
  }
}

function CompanyOverview(props) {
  const rows = props.cells.map(cell => {
    const v = calculateValues(cell.latestData);
    return {
      cellId: cell.cellId,
      average: v.average,
      min: v.min,
      max: v.max,
      batteryLevel: v.minBatt,
      signal: v.minRSSI,
      batteryType: v.batteryType,
      minBattSensorId: v.minBattSensorId,
      minSigSensorId: v.minSigSensorId,
      minTempSensorId: v.minTempSensorId,
      maxTempSensorId: v.maxTempSensorId
    }
  })

  const headCells = [
    { id: 'cellId', numeric: false, disablePadding: false, sortable: true, label: 'Koelcel' },
    { id: 'average', numeric: true, disablePadding: true, sortable: true, label: 'Gem.°C' },
    { id: 'min', numeric: true, disablePadding: true, sortable: true, label: 'Min.°C' },
    { id: 'max', numeric: true, disablePadding: true, sortable: true, label: 'Max.°C' },
    { id: 'batteryLevel', numeric: true, disablePadding: true, sortable: true, label: <Aux>Min. batterij&shy;niveau</Aux> },
    { id: 'signal', numeric: true, disablePadding: true, sortable: true, label: <Aux>Min. signaal&shy;sterkte</Aux>, tooltip: <SignalTooltip /> },
  ];

  const handleClick = (row) => {
    props.history.push("/table/" + row.cellId)
  }

  return (
    <div className={classes.CompanyOverview}>
      <SortableTable rows={rows} headCells={headCells} handleClick={handleClick} clickable />
    </div>
  );

}

export default withRouter(CompanyOverview);